
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component
export default class PopoverInput extends Vue {
    // props
    @Prop() value!: string
    @Prop() list!: []
    @Prop({ default: 'number' }) type!: 'text' | 'number'
    @Prop({ default: '250' }) width!: number | string
    @Prop({ default: false }) disabled!: boolean
    @Prop() tips!: string
    visible = false
    factory_id = ''
    @Watch('value', { immediate: true })
    valueChange (val: string) {
      this.factory_id = val
    }

    handleConfirm () {
      this.close()
      this.$emit('confirm', this.factory_id)
    }

    handleOpen () {
      if (this.disabled) return
      this.visible = true
    }

    close () {
      this.visible = false
    }
}
